/* .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-wrap {
    flex: 1;
} */
body {
    overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    body {
        overflow-y: auto;
    }
}
.page-content {
    display: grid;
    grid-template-rows: auto auto 1fr;
}
.container-fluid {
    display: grid;
    grid-template-rows: auto 1fr;
}

.page-wrapper {
    /* background: #eef5f9; */
    position: relative;
    transition: 0.2s ease-in;
    display: block;
    margin-top: 100px;
}

#main-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 0;
}
.sidebar-wrapper {
    position: fixed;
}

.left-sidebar {
    position: absolute;
    width: 220px;
    height: 100%;
    top: 0px;
    z-index: 10;
    padding-top: 0;
    background: #fff;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    /* transition: 0.2s ease-in; */
    transform: translateX(-100%);
    /* transition: transform 0.6s ease; */
    transition-delay: 0.4s;
}
.headercss {
    position: fixed;
    z-index: 10;
    padding: 0;
    margin: 0;
}
