@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
/* .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-wrap {
    flex: 1;
} */
body {
    overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    body {
        overflow-y: auto;
    }
}
.page-content {
    display: grid;
    grid-template-rows: auto auto 1fr;
}
.container-fluid {
    display: grid;
    grid-template-rows: auto 1fr;
}

.page-wrapper {
    /* background: #eef5f9; */
    position: relative;
    transition: 0.2s ease-in;
    display: block;
    margin-top: 100px;
}

#main-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 0;
}
.sidebar-wrapper {
    position: fixed;
}

.left-sidebar {
    position: absolute;
    width: 220px;
    height: 100%;
    top: 0px;
    z-index: 10;
    padding-top: 0;
    background: #fff;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    /* transition: 0.2s ease-in; */
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    /* transition: transform 0.6s ease; */
    transition-delay: 0.4s;
}
.headercss {
    position: fixed;
    z-index: 10;
    padding: 0;
    margin: 0;
}

.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}

.motif-modal-style {
    max-width: 800px !important;
    max-height: 100px !important;
    border-radius: 12px;
}

.history-modal-style {
    max-width: 1500px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}

.text-area-style {
    width: 100%;
    height: 200px;
}

.input-style {
    size: 16px;
    width: 100%;
    height: 40px;
    top: 530px;
    left: 1367px;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    /* border-radius: 5px; */
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

.comment-input-style {
    size: 16px;
    width: 100%;
    height: 120px;
    top: 530px;
    left: 1367px;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    /* border-radius: 5px; */
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

.comment-add-icon-style {
    height: 70px;
    width: 70px;
    margin-bottom: 13px;
    margin-left: -25px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 3px;
    margin-top: 3px;
    border-radius: 19px;
    width: 33px;
    height: 31px;
    /* border: 2px solid #cfcdcd;

    padding: 5px;

    margin-top: 5px;

    border-radius: 19px;

    width: 36px;

    height: 31px;

    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);

    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%); */
}

.responsive-toast {
    width: 300px;
}

@media (min-width: 993px) {
    .responsive-toast {
        width: 600px;
    }
}

.pre-scrollable {
    max-height: 1040px;
    overflow-y: scroll;
}
.title-text-style {
    top: 321px;
    left: 528px;
    width: 361px;
    height: 0px;
    text-align: left;
    font: normal normal bold 16px/15px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}

.title-legend.style {
    top: 380px;
    left: 572px;
    width: 180px;
    height: 21px;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}

.text-legend-style {
    top: 442px;
    left: 580px;
    /* width: 200px; */
    height: 21px;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}

.text-hover-legend-style {
    top: 466px;
    left: 580px;
    /* width: 201px; */
    height: 17px;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.vertical-line {
    border-left: 2px solid #d6d6d6;
    display: inline-block;
    height: 30px;
    margin: 0 0px;
}

.configuration-modal-style {
    max-width: 800px !important;
    max-height: 100px !important;
    border-radius: 12px;
    margin-top: 10px;
    border-radius: 10px;
}

.advanced-information-modal-style {
    max-width: 450px !important;
    max-height: 100px !important;
    border-radius: 12px;
    margin-top: 10px;
    border-radius: 10px;
}

.select-style {
    width: 280px;
    height: 35px;
    border-radius: 6px;
    opacity: 1px;
}

.css-1unj10r-MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-bottom: 1rem;
    margin-top: 0 !important;
    padding-top: 0 !important;
    max-height: 500px !important;
    min-height: 500px !important;
}

.page-content {
    height: 880px !important;
}
.updateAlerte {
    width: 150px;
    height: 150px;
    border-radius: 12px;
        margin-top: 10px;
        border-radius: 10px;
}
.archivageModal{
    width: 400px;
    height: 150px;
    border-radius: 12px;
        margin-top: 0px;
        border-radius: 0px;

}
.border-Footer__clz {
    border-bottom: 1px solid #ced4da;
}

.card_cl__clz {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    border: 0;
}

.style-card_clz {
    border-color: #ced4da !important;
    border-width: thin;
}

.card_c__clz {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    border: 0;
}

.fieldsetInterne__clz {
    border: 1px solid #dedede;
    border-radius: 5px;
    opacity: 1;
}

.react-datepicker__clz {
    width: 73px !important;
    height: 30px;
    font-size: 14px;
}

.style-modal__clz {
    width: 650px;
    min-width: 700px;
}

.btn-style__clz:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem white !important;
}

.style-calender__clz {
    min-width: 200px !important;
    width: 200px !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: white !important;
    border-bottom: 1px solid #f0e7e7;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__month-container {
    width: 320px;
    max-width: 320px;
    overflow: auto;
    height: 274px;
    max-height: 274px;
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1rem;
    color: #000;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #0c0a09 !important;
    color: #fff;
}
.div-notification__clz {
    width: 100%;
    background-color: white;
    border-radius: 9px;
    margin-bottom: 0.5%;
}

.col-icon-notification__clz {
    background-color: #292929;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.text-notification__clz {
    font: normal normal normal 20px/27px Segoe UI;
}

.card-header__clz,
.card-footer__clz {
    padding: 0px !important;
    background-color: transparent !important;
}

.message-info__clz {
    display: none !important;
}

td {
    -webkit-justify-content: center !important;
            justify-content: center !important;
}

tr th:nth-child(1) {
    width: 50%;
}
.css-1unj10r-MuiTableContainer-root{

    overflow-x: hidden !important;

}
html {
    font-family: sans-serif;
    font-size: 14px;
}

table {
    border: 1px solid lightgray;
}

tbody {
    border-bottom: 1px solid lightgray;
}

th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
}

tfoot {
    color: gray;
}

tfoot th {
    font-weight: normal;
}

.lower-right {
    float: right;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.td-table-style {
    box-sizing: border-box;
    -webkit-flex: 150 0 auto;
            flex: 150 0 auto;
    min-width: 10px;
    width: 150px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}

.title-modal-style {
    top: 174px;
    left: 350px;
    width: 200px;
    height: 0px;

    /* UI Properties */

    text-align: left;
    font: normal normal bold 15px/14px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}
.text-area-style {
    top: 457px;
    left: 1202px;
    width: 400px;
    height: 164px;

    /* UI Properties */

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
}

::-webkit-input-placeholder {
    top: 464px;
    left: 1226px;
    width: 39px;
    height: 21px;

    /* UI Properties */

    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

:-ms-input-placeholder {
    top: 464px;
    left: 1226px;
    width: 39px;
    height: 21px;

    /* UI Properties */

    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

::placeholder {
    top: 464px;
    left: 1226px;
    width: 39px;
    height: 21px;

    /* UI Properties */

    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

.text-style-confirmation {
    top: 503px;
    left: 677px;
    width: 529px;
    height: 58px;
}

.title-style-confirmation {
    /* Layout Properties */
    top: 411px;
    left: 658px;
    width: 245px;
    height: 10px !important;
    text-align: left;
    font: normal normal bold 16px/15px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}

.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}
.selectMotif{
    border : red
}
.css-1unj10r-MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-bottom: 1rem;
    margin-top: 0 !important;
    padding-top: 0 !important;
    max-height: 500px !important;
    min-height: 500px !important;
}
.page-content {
    height: 880px !important;
    /* overflow: hidden !important */
}
.makeStyles-SvgNoDataCss-19 {
    height: 90% !important;
}

.dropdown-menu {
    min-width: 5rem !important;
}

.language-switch {
    top: 5px !important;
    left: 12px !important;
}

.react-tel-input .form-control {
    width:
            225px !important;
}

.required__clz:after {
    content: " *";
    color: red;
}

.hovertext {
    position: relative;
    cursor: pointer;
}

.hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: -100%;
}

.hovertext:hover:before {
    opacity: 1;
    visibility: visible;

}
.hovertextassociated {
    position: relative;
    cursor: pointer;
}

.hovertextassociated:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: -160%;
}

.hovertextassociated:hover:before {
    opacity: 1;
    visibility: visible;

}

.hovertexttypeCompte {
    position: relative;
    cursor: pointer;
}

.hovertexttypeCompte:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: 70%;
}

.hovertexttypeCompte:hover:before {
    opacity: 1;
    visibility: visible;

}

.hoverTable {
    position: relative;
    cursor: pointer;
}

.hoverTable:before {
    content: attr(Table-hover);
    visibility: hidden;
    opacity: 0;
    width: 'fit-content';
    height: 'fit-content';
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: -100%;
}

.hoverTable:hover:before {
    opacity: 1;
    visibility: visible;

}
/* th,
td {
    border: 1px solid rgb(208, 197, 197);
    padding: 8px;
}

thead th {
    width: 25%;
} */



.updateAlerte{
    width:659px;
    height: 285px;
}
.invalid-feedback {
    /* display: none; */
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.text-danger {
    color: #dc3545 !important;
}
.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.css-1s2u09g-control {   
    border-color: #767676 !important
}
/* .input-style:hover{
    border-color: #2684ff !important ;
    box-shadow:0 0 0 1px #2684ff;
}
    */
.modal-wrapper__clz .modal-content {
    height: 1000px !important;
    width: 1800px !important;
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

#nprogress .bar,
#nprogress .spinner {
    z-index: 999999 !important;
}

@media (max-width: 576px) {
    .login-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
                clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .login-card {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .login-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }
    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
                clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .login-card {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto;
        height: 100%;
    }
    .login-card {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .login-card {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .login-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
        width: 30%;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }
    .video {
        width: 120%;
        height: auto;
    }

    .login-card {
        left: 60% !important;
    }
}

.login-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.login-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.login-card {
    font-family: "Poppins";
    left: 50%;
    top: 50%;
    width: 30%;
    height: 47%;
}

.login-card h3 {
    font-size: 35px;
}

.login-card .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 38px;
    right: 15px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

.submit-button__clz{
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}

input::-webkit-input-placeholder {
    width: 100% !important;
}

input:-ms-input-placeholder {
    width: 100% !important;
}

input::placeholder {
    width: 100% !important;
}
