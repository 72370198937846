.required__clz:after {
    content: " *";
    color: red;
}

.hovertext {
    position: relative;
    cursor: pointer;
}

.hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: -100%;
}

.hovertext:hover:before {
    opacity: 1;
    visibility: visible;

}
.hovertextassociated {
    position: relative;
    cursor: pointer;
}

.hovertextassociated:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: -160%;
}

.hovertextassociated:hover:before {
    opacity: 1;
    visibility: visible;

}

.hovertexttypeCompte {
    position: relative;
    cursor: pointer;
}

.hovertexttypeCompte:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: 70%;
}

.hovertexttypeCompte:hover:before {
    opacity: 1;
    visibility: visible;

}

.hoverTable {
    position: relative;
    cursor: pointer;
}

.hoverTable:before {
    content: attr(Table-hover);
    visibility: hidden;
    opacity: 0;
    width: 'fit-content';
    height: 'fit-content';
    background-color: #00000091;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    font-size: 10px;
    z-index: 1;
    left: 0;
    bottom: -100%;
}

.hoverTable:hover:before {
    opacity: 1;
    visibility: visible;

}
/* th,
td {
    border: 1px solid rgb(208, 197, 197);
    padding: 8px;
}

thead th {
    width: 25%;
} */



.updateAlerte{
    width:659px;
    height: 285px;
}
.invalid-feedback {
    /* display: none; */
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.text-danger {
    color: #dc3545 !important;
}
.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}
