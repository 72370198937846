td {
    justify-content: center !important;
}

tr th:nth-child(1) {
    width: 50%;
}
.css-1unj10r-MuiTableContainer-root{

    overflow-x: hidden !important;

}