.css-1unj10r-MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-bottom: 1rem;
    margin-top: 0 !important;
    padding-top: 0 !important;
    max-height: 500px !important;
    min-height: 500px !important;
}
.page-content {
    height: 880px !important;
    /* overflow: hidden !important */
}
.makeStyles-SvgNoDataCss-19 {
    height: 90% !important;
}

.dropdown-menu {
    min-width: 5rem !important;
}

.language-switch {
    top: 5px !important;
    left: 12px !important;
}

.react-tel-input .form-control {
    width:
            225px !important;
}
