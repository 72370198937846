html {
    font-family: sans-serif;
    font-size: 14px;
}

table {
    border: 1px solid lightgray;
}

tbody {
    border-bottom: 1px solid lightgray;
}

th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
}

tfoot {
    color: gray;
}

tfoot th {
    font-weight: normal;
}

.lower-right {
    float: right;
    display: flex;
    align-items: center;
}

.td-table-style {
    box-sizing: border-box;
    flex: 150 0 auto;
    min-width: 10px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.title-modal-style {
    top: 174px;
    left: 350px;
    width: 200px;
    height: 0px;

    /* UI Properties */

    text-align: left;
    font: normal normal bold 15px/14px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}
.text-area-style {
    top: 457px;
    left: 1202px;
    width: 400px;
    height: 164px;

    /* UI Properties */

    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c6c6c6;
    border-radius: 6px;
    opacity: 1;
}

::placeholder {
    top: 464px;
    left: 1226px;
    width: 39px;
    height: 21px;

    /* UI Properties */

    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

.text-style-confirmation {
    top: 503px;
    left: 677px;
    width: 529px;
    height: 58px;
}

.title-style-confirmation {
    /* Layout Properties */
    top: 411px;
    left: 658px;
    width: 245px;
    height: 10px !important;
    text-align: left;
    font: normal normal bold 16px/15px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}

.modal-wrapper__clz .modal-content {
    height: 95vh !important;
    width: 95vw !important;
}
.selectMotif{
    border : red
}