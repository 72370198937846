.custom-modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}

.motif-modal-style {
    max-width: 800px !important;
    max-height: 100px !important;
    border-radius: 12px;
}

.history-modal-style {
    max-width: 1500px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}
.modal-style {
    max-width: 1700px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 12px;
}

.text-area-style {
    width: 100%;
    height: 200px;
}

.input-style {
    size: 16px;
    width: 100%;
    height: 40px;
    top: 530px;
    left: 1367px;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    /* border-radius: 5px; */
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

.comment-input-style {
    size: 16px;
    width: 100%;
    height: 120px;
    top: 530px;
    left: 1367px;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    /* border-radius: 5px; */
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 0.5;
}

.comment-add-icon-style {
    height: 70px;
    width: 70px;
    margin-bottom: 13px;
    margin-left: -25px;
}

.badge__clz {
    border: 2px solid #cfcdcd;
    padding: 3px;
    margin-top: 3px;
    border-radius: 19px;
    width: 33px;
    height: 31px;
    /* border: 2px solid #cfcdcd;

    padding: 5px;

    margin-top: 5px;

    border-radius: 19px;

    width: 36px;

    height: 31px;

    box-shadow: 12px 12px 5px -2px rgb(199 182 182 / 69%);

    -webkit-box-shadow: 6px 3px 8px -2px rgb(199 182 182 / 69%); */
}
