.pre-scrollable {
    max-height: 1040px;
    overflow-y: scroll;
}
.title-text-style {
    top: 321px;
    left: 528px;
    width: 361px;
    height: 0px;
    text-align: left;
    font: normal normal bold 16px/15px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    text-transform: uppercase;
    opacity: 1;
}

.title-legend.style {
    top: 380px;
    left: 572px;
    width: 180px;
    height: 21px;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}

.text-legend-style {
    top: 442px;
    left: 580px;
    /* width: 200px; */
    height: 21px;
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}

.text-hover-legend-style {
    top: 466px;
    left: 580px;
    /* width: 201px; */
    height: 17px;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: 0px;
    color: #2b2828;
    opacity: 1;
}
.vertical-line {
    border-left: 2px solid #d6d6d6;
    display: inline-block;
    height: 30px;
    margin: 0 0px;
}

.configuration-modal-style {
    max-width: 800px !important;
    max-height: 100px !important;
    border-radius: 12px;
    margin-top: 10px;
    border-radius: 10px;
}

.advanced-information-modal-style {
    max-width: 450px !important;
    max-height: 100px !important;
    border-radius: 12px;
    margin-top: 10px;
    border-radius: 10px;
}

.select-style {
    width: 280px;
    height: 35px;
    border-radius: 6px;
    opacity: 1px;
}

.css-1unj10r-MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-bottom: 1rem;
    margin-top: 0 !important;
    padding-top: 0 !important;
    max-height: 500px !important;
    min-height: 500px !important;
}

.page-content {
    height: 880px !important;
}
.updateAlerte {
    width: 150px;
    height: 150px;
    border-radius: 12px;
        margin-top: 10px;
        border-radius: 10px;
}
.archivageModal{
    width: 400px;
    height: 150px;
    border-radius: 12px;
        margin-top: 0px;
        border-radius: 0px;

}